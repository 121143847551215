.info {
    overflow: hidden;
    &.benefits {
        .txtimg__image img, .txtimg__slider img {
            aspect-ratio: 21/9;
            object-fit: cover;
        }
        .txtimg__content {
            padding-left: 8vw;
            padding-right: 8vw;
            @include mq($until: xl){
                padding-left: 100px;
                padding-right: 100px;
            }
            @include mq($until: lg){
                padding-left: 50px;
                padding-right: 50px;
            }
            @include mq($until: sm){
                padding-left: 30px;
                padding-right: 30px;
            }
            @include mq($until: xs){
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}
