.header__submenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #24252a;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: unset;
  right: 0;
  top: 150%;
  padding: 0;
  height: auto;
  max-height: 900px;
  min-width: 150px;
  opacity: 1;
  z-index: 9;
  transform: scaleY(1);
  transform-origin: top center;
  transition: opacity .6s ease-in-out, transform .3s ease-in-out;
  list-style: none;

  &--fullscreen {
    position: static;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &.header__submenu--hidden{
      display: none;
    }
  }

  &--hidden {
    opacity: 0;
    transform: scaleY(0);
  }

  .header__menuItem {
    width: 100%;
    margin: 0;
    list-style: none;
    &--fullscreen {
      margin: 0;
    }
  }

  .header__menuLink {
    color: #fff;
    white-space: nowrap;
    padding: 15px 20px;
    
    &--fullscreen {
      font-size: rem(16px);
      margin-bottom: 5px;
      text-align: center;
    }
  }
}

.header__menuItem:nth-child(1) .header__submenu {
  right: unset;
  left: 0;
}
