.video {
    //background: red;
    position: relative;
    font-size: 0;
    &.full {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            min-width: 100%;
            min-height: 100%;
            transition: ease 0.3s all;
            transform: translateY(-50%) translateX(-50%);
            //background: yellow;
            display: block;
        }
    }

    &.a1, &.a1 iframe, &.a1 video {
        aspect-ratio: 16/9;
    }
    &.a2, &.a2 iframe, &.a2 video {
        aspect-ratio: 4/3;
    }
    &.a3, &.a3 iframe, &.a3 video {
        aspect-ratio: 3/2;
    }
    &.a4, &.a4 iframe, &.a4 video {
        aspect-ratio: 21/9;
    }
    &.playing {
        .icon, .overlay, .poster {
            opacity: 0;
        }
        .icon {
            visibility: hidden;
        }
    }
    &-container {
        font-size: 0;
    }
    &:not(.facebook) iframe {
        width: 100%;
        height: auto;
        display: block;
    }
    &.facebook {
        iframe {
            margin: 0 auto;
            display: block;
        }
        .stuck {
            width: unset;
            height: unset;
            transform: scale(0.8);
        }
    }
    video {
        //background: yellow;
        &.grayscale {
            filter: grayscale(1);
        }
    }
    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 3;
        cursor: pointer;
        transition: ease 0.3s all;
        svg {
            width: 30px;
            height: auto;
        }
    }
    .overlay {
        z-index: 2;
        transition: ease 0.3s all;
    }
    .poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 1;
        transition: ease 0.3s all;
        background-color: #fff;
    }
    .stuck {
        background-color: #000;
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 260px;
        height: 150px;
        transform: translateY(100%);
        animation: fade-in-up 0.75s ease forwards;
        z-index: 9999;
        overflow: hidden;
        video, iframe {
            aspect-ratio: unset;
        }
    }
}
@keyframes fade-in-up {
    0% {
        opacity: 0;
   }
    100% {
        transform: translateY(0);
        opacity: 1;
   }
}
