.homepage {
    .hero {
        align-items: flex-end;
        .builder-column {
            max-width: 900px;
            text-shadow: 0px 0px 60px #000;
        }
    
    }
}
