.hero {
    position: relative;
    background: #301919;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    &__content {
        position: relative;
        z-index: 1;
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
        h1 {
            color: #fff;
            margin-bottom: 0;
            font-size: rem(42px);
            font-weight: 700;
        }
        h2 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0;
            font-size: rem(24px);
            font-weight: 700;
        }
        p {
            color: #fff;
            &:first-of-type {
                margin-top: 20px;
            }
        }
        a {
            display: block;
            align-self: unset;
            margin-top: 20px;
        }
        &--center {
            text-align: center;
            align-items: center;
            margin: 0 auto;
        }
        &--right {
            margin: 0 0 0 auto;
            text-align: right;
            align-items: flex-end;
        }
    }
}
