.news{
    &__date{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        span {
        font-weight: 600;
        }
        svg {
            width: 20px;
            height: auto;
            margin-right: 15px;
        }
    }
    &__category{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        p {
            font-weight: 600;
            margin-right: 5px;
        }
    }
    &__categories{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        .single span {
            color: #fff;
            text-transform: uppercase;
            font-size: 13px;
            padding: 5px 10px;
            border-radius: 3px;
            background-color: $color-main;
        }
    }
    &__image{
        margin-bottom: 30px;
    }
    .gallery{
        margin-top: 60px;
        margin-bottom: 30px;
    }
}