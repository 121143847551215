div#flags {
  display: flex;
  gap: 15px;
  align-items: center;
  a {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .code {
    text-transform: uppercase;
  }
  .flag {
    font-size: 0;
  }
  img {
      height: auto;
      opacity: 0.5;
      transition: all .3s;
      @include mq($until: xl) {
        width: 30px;
      }    
      &:hover {
          opacity: 1;
      }
  }

  .active-lang  {
      img {
          opacity: 1;
      }
  }

  @include mq($until: md) {
    margin-left: 15px;
    margin-top: 15px;
    img {
      width: 30px;
    }
  }
}


.lang-switcher {
  position: relative;
  display: inline-block;
  .label {
      border: 1px solid rgba($color-main, 0.1);
      border-radius: 5px;
      cursor: pointer;
      transition: ease 0.3s all;
      display: flex;
      color: $paragraph-color;
      padding: 10px 10px;
      align-items: center;
      height: 50px;
      column-gap: 10px;
      &:hover {
          border-color: $color-main;
          background-color: $color-main;
          color: #fff;
          a, i, span {
              color: #fff;
          }
          .icon svg {
            fill: #fff;
          }
          .arrow svg line {
            stroke: #fff;
          }
      }
      .code {
        text-transform: uppercase;
      }
      .flag {
        font-size: 0;
      }
      .icon svg {
        width: 28px;
        height: auto;
        fill: $paragraph-color;
      }
      .arrow svg {
        width: 14px;
        height: auto;
        transition: ease 0.3s all;
        line {
          stroke-width: 2;
          stroke: $paragraph-color;
        }
      }
  }
  .list {
      display: none;
      position: absolute;
      z-index: 9;
      top: 100%;
      margin: 10px 0 0 0;
      padding: 0;
      width: 100%;
      border: 1px solid rgba($color-main, 0.1);
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      li {
          list-style: none;
          border-bottom: 1px solid rgba($color-main, 0.1);
          padding: 0;
          margin: 0;
          &:last-of-type {
              border-bottom: none;
          }
          &.current {
              background-color: rgba($color-main, 0.05);
          }
          &:before {
            display: none;
          }
      }
      a {
          padding: 10px 10px;
          display: flex;
          align-items: center;
          color: $paragraph-color;
          text-decoration: none;
          transition: ease 0.3s background-color;
          column-gap: 10px;
          &:hover {
              background-color: rgba($color-main, 0.05);
          }
      }
      img {
        max-width: unset;
      }
      .flag {
        font-size: 0;
      }
      .code {
        text-transform: uppercase;
      }
      &--autowidth {
          width: auto;
      }
      &--toright {
          right: 0;
      }
      &--right {
          top: 0;
          left: 100%;
          margin: 0 0 0 10px;        
      }
      &--left {
          top: 0;
          right: 100%;
          margin: 0 10px 0 0;
      }
      &--above {
          top: unset;
          bottom: 100%;
          margin: 0 0 10px 0;        
      }
  }
  &--opened {
      .label {
          border-color: $color-main;
          background-color: $color-main;
          color: #fff;
          a, i, span {
              color: #fff;
          }
          .icon svg {
            fill: #fff;
          }
          .arrow svg {
              transform: rotate(180deg);
              line {
                stroke: #fff;
              }
          }

      }
  }
}
