.subsite {
    padding-top: 100px;
    .header {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
        position: absolute;
        width: 100%;
        top: 0;
        &.sticky {
            position: fixed;
        }
        &__logo--light {
            display: none;
        }
        &__logo--dark {
            display: block;
        }
    }
    .hero {
        min-height: unset;
        height: 60vh;
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
        h1 {
            margin-bottom: 80px;
        }
    }
}
