html, body {
    scroll-behavior: smooth;
}
.scrolltop {
    display: none;
    a.top {
        position: fixed;
        display: flex;
        align-items: center;
        bottom: 10px;      
        right: 10px; 
        width: 50px;
        height: 50px;
        padding: 0 10px;
        background-color: $color-main;
        border-radius: 100px;
        z-index: 9;
        transition: ease 0.3s all;
        &:hover {
            background-color: $color-extra;
        }
        svg {
            width: 100%;
            height: auto;
        }
    }
}
