.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  background-color: $color-header;
  z-index: 1001;

  &--absolute {
    position: absolute;
    width: 100%;
    border-bottom: none;
    background: transparent;
    .header__menuLink {
      color: #fff;
    }
    .header__submenu .header__menuLink {
      color: #fff;
      &:active, &--active, &--open, &:hover {
        color: $color-main;
      }
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      width: 100%;
      background-color: $color-header;
      .header__logo--dark {
        display: block;
      }
      .header__logo--light {
        display: none;
      }
      .header__menuLink {
        color: #000;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
      .header__submenu .header__menuLink {
        color: #fff;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    &--dark {
      display: none;
    }
    img {
      height: auto;
      @include mq($until: lg) {
        width: 13vw;
      }
      @include mq($until: md) {
        width: 140px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    column-gap: 30px;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #000;
    padding: 20px 25px;
    display: block;
    font-weight: 400;
    font-size: rem(19px);
    @include mq($until: xl) {
      padding: 20px 20px;
    }    
    @include mq($until: lg) {
      padding: 20px 15px;
    }    
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }
}
